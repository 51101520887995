<template>
  <div class="form-group">
    <label v-if="label" class="form-label">{{ label }}</label>

    <div class="input-wrap">
      <i-mask
        class="form-input"
        type="text"
        :class="iClass"
        :placeholder="placeholder"
        @click="$emit('click', $event)"
        @keyup="$emit('keyup', $event)"
        @keydown="$emit('keydown', $event)"
        @keypress="$emit('keypress', $event)"
        @change="$emit('change', $event)"
        @blur="$emit('blur', $event)"
        @focus="$emit('focus', $event)"
        :unmask="unmask"
        :mask="mask"
        v-model="_value"
      />
    </div>

    <span v-if="help !== undefined" class="form-help">{{ help }}</span>
  </div>
</template>

<script>
import IMask from "../modules/imask";

export default {
  props: {
    placeholder: [String, Number],
    label: [String, Number],
    help: {
      type: [String, Number],
      default: undefined
    },
    masked: {
      type: Boolean,
      default: false
    },
    value: [String, Number],
    unmask: {
      type: Boolean,
      default: false
    },
    mask: Object,
    iClass: Object
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  components: {
    iMask: IMask
  }
};
</script>
